import { template as template_5baf487ebe104089b5aa1d23c46340a2 } from "@ember/template-compiler";
const FKControlMenuContainer = template_5baf487ebe104089b5aa1d23c46340a2(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
