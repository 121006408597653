import { template as template_ceba9bdad48641869b7f33f5ffb76a88 } from "@ember/template-compiler";
const FKLabel = template_ceba9bdad48641869b7f33f5ffb76a88(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
